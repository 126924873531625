<template>
  <v-row class="props-list">
    <v-col md="2">Иминительный</v-col>
    <v-col md="10">{{ pluralCases.nominative }}</v-col>

    <v-col md="2">Винительный</v-col>
    <v-col md="10">{{ pluralCases.genitive }}</v-col>

    <v-col md="2">Дательный</v-col>
    <v-col md="10">{{ pluralCases.dative }}</v-col>

    <v-col md="2">Винительный</v-col>
    <v-col md="10">{{ pluralCases.accusative }}</v-col>

    <v-col md="2">Творительный</v-col>
    <v-col md="10">{{ pluralCases.ablative }}</v-col>

    <v-col md="2">Предложный</v-col>
    <v-col md="10">{{ pluralCases.prepositional }}</v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    pluralCases: {
      type: Object,
      required: true
    }
  }
}
</script>