<template>
  <v-row class="props-list">
    <v-col md="2">Несколько</v-col>
    <v-col md="10">{{ plural.few }}</v-col>

    <v-col md="2">Много</v-col>
    <v-col md="10">{{ plural.many }}</v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    plural: {
      type: Object,
      required: true
    }
  }
}
</script>