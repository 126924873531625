<template>
  <div class="pa-3">
    <div class="text-h6 font-weight-regular mb-4">По падежам</div>

    <currency-declensions-cases :cases="declensions.cases" />

    <v-divider class="my-3" />

    <div class="text-h6 font-weight-regular mb-4">По множеству</div>

    <currency-declensions-plural :plural="declensions.plural" />

    <v-divider class="my-3" />

    <div class="text-h6 font-weight-regular mb-4">По падежам во множестве </div>

    <currency-declensions-plural-cases :plural-cases="declensions.pluralCases" />
  </div>
</template>

<script>
import CurrencyDeclensionsCases from "./cases";
import CurrencyDeclensionsPlural from "./plural";
import CurrencyDeclensionsPluralCases from "./pluralCases";

export default {
  props:{
    declensions: {
      type: Object,
      required: true
    }
  },
  components: {
    CurrencyDeclensionsCases,
    CurrencyDeclensionsPlural,
    CurrencyDeclensionsPluralCases
  }
}
</script>
