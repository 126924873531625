<template>
  <v-row class="props-list">
    <v-col md="2">Альфа-код</v-col>
    <v-col md="10">{{ currency.alphaCode }}</v-col>

    <v-col md="2">Название</v-col>

    <v-col md="10">
      <v-text-field :value="currency.name" dense @input="setField('name', $event)" />
    </v-col>

    <v-col md="2">Символ</v-col>
    <v-col md="10">
      <v-text-field :value="currency.symbol" dense @input="setField('symbol', $event)" />
    </v-col>

    <v-col md="2">Цифровой код</v-col>
    <v-col md="10">
      <v-text-field :value="currency.numericCode" dense @input="setField('numericCode', $event)" />
    </v-col>

    <v-col md="2">Описание</v-col>
    <v-col md="10">
      <wysiwyg-editor class="mt-4" :value="currency.description" @input="setField('description', $event)" />
    </v-col>

    <v-col class="font-weight-regular" md="12">
      <v-checkbox v-model="isActiveProxy" class="my-0" label="Валюта активна" hide-details />
    </v-col>

    <v-col class="font-weight-regular" md="12">
      <v-checkbox v-model="isBaseCurrencyProxy" class="my-0" label="Основная" hide-details />
    </v-col>
  </v-row>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    currency: {
      type: Object,
      required: true
    }
  },
  computed: {
    isActiveProxy: {
      get() {
        return this.currency.isActive;
      },
      set(value) {
        this.setField("isActive", value);
      }
    },
    isBaseCurrencyProxy: {
      get() {
        return this.currency.isBaseCurrency;
      },
      set(value) {
        this.setField("isBaseCurrency", value);
      }
    }
  }
};
</script>
