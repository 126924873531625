export class UpdateCurrencyDto {
  constructor(currency) {
    this.symbol = currency.symbol;
    this.name = currency.name;
    this.numericCode = currency.numericCode;
    this.isActive = currency.isActive;
    this.description = currency.description;
    this.isBaseCurrency = currency.isBaseCurrency;
  }
}
