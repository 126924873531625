<template>
  <v-row class="props-list">
    <v-col md="2">Иминительный</v-col>
    <v-col md="10">{{ cases.nominative }}</v-col>

    <v-col md="2">Винительный</v-col>
    <v-col md="10">{{ cases.genitive }}</v-col>

    <v-col md="2">Дательный</v-col>
    <v-col md="10">{{ cases.dative }}</v-col>

    <v-col md="2">Винительный</v-col>
    <v-col md="10">{{ cases.accusative }}</v-col>

    <v-col md="2">Творительный</v-col>
    <v-col md="10">{{ cases.ablative }}</v-col>

    <v-col md="2">Предложный</v-col>
    <v-col md="10">{{ cases.prepositional }}</v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    cases: {
      type: Object,
      required: true
    }
  }
}
</script>