<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'currencies-index' }" />

    <template v-if="currency">
      <h2 class="mb-4">{{ currency.name }}</h2>

      <v-sheet elevation="1">
        <v-tabs background-color="deep-purple secondary" center-active dark hide-slider>
          <v-tab>Обзор</v-tab>
          <v-tab>Склонения</v-tab>

          <v-tab-item class="pa-3">
            <currency-summary :currency="currency" @change-field="changeField" />

            <v-divider class="my-3" />

            <div class="text-right">
              <v-btn color="accent" :loading="updating" @click="updateCurrency">Сохранить</v-btn>
            </div>
          </v-tab-item>

          <v-tab-item>
            <currency-declensions :declensions="currency.declensions" />
          </v-tab-item>
        </v-tabs>
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Валюта по альфа-коду "{{ alphaCode }}" не найдена</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { UpdateCurrencyDto } from "~/libs/currency/update-currency-dto";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import CurrencySummary from "~/components/currencies/alphaCode/summary";
import CurrencyDeclensions from "~/components/currencies/alphaCode/declensions/index";

export default {
  mixins: [editorForm],
  data() {
    return {
      currency: null,
      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchCurrency();
  },
  computed: {
    alphaCode() {
      return this.$route.params.alphaCode;
    }
  },
  methods: {
    async fetchCurrency() {
      this.loading = true;

      try {
        this.currency = await this.$axios.$get(`currencies/${this.alphaCode}`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получена информация по альфа-коду: "${this.alphaCode}"` + html);
      } finally {
        this.loading = false;
      }
    },
    async updateCurrency() {
      this.updating = true;

      try {
        this.currency = await this.$axios.$patch(`currencies/${this.alphaCode}`, new UpdateCurrencyDto(this.currency));

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    },
    changeField(payload) {
      this.commitChange(this.currency, payload);
    }
  },
  components: {
    CurrencySummary,
    CurrencyDeclensions
  }
}
</script>
